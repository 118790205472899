<template>
  <div>
    <v-text-field type="number" label="Beet-Nummer" v-model="currBeet"
      >asdfasdf</v-text-field
    >
    <v-banner elevation="0">Grundstück:</v-banner>

    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in list"
          :key="i"
          :disabled="beetNotSet"
          @click="gotoBeet(item)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- <v-icon
      @click="addGrundstueck">mdi-plus-circle</v-icon> -->

    <!-- <v-spacer></v-spacer> -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="grey" dark v-bind="attrs" v-on="on">
            Grundstück hinzufügen
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">neues Grundstück</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col sm="6" md="4">
                  <v-text-field label="Bezeichnung"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDlg"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "navigateBeet",

  data() {
    return {
      dialog: false,
      currBeet: null,
      beetNotSet: true,
      list: [{ name: "name0" }, { name: "name1a" }],
    };
  },
  methods: {
    loadGrundstuecke() {
      axios
        .get(this.$hostname + "/getGrundstuecke.php")
        .then((response) => (this.list = response.data));
      // axios.get('http://localhost:80/stauden_backend_php/getGrundstuecke.php').then(response => (console.log(response.data)));
    },
    gotoBeet(item) {
      //   console.log(item);
      this.$router.push({
        name: "beetView",
        params: {
          grundstuck: item["ID"],
          grundstuckName: item["name"],
          beet: this.currBeet,
        },
      });
    },
    addGrundstueck() {
      
    },
    closeDlg() {
      this.dialog = false;
    },
  },
  watch: {
    currBeet: function () {
      if (this.currBeet != null) {
        if (this.currBeet.length > 0) {
          // console.log("asdasd");
          // console.log(this.currBeet);
          this.beetNotSet = false;
        } else {
          this.beetNotSet = true;
        }
      } else {
        this.beetNotSet = true;
      }
    },
  },
  beforeMount: function () {
    this.loadGrundstuecke();
  },
};
</script>
